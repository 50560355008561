import logo from './logo.svg';
import './App.css';
import Dashboard from './Commponents/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Staking from './Commponents/Staking';
import LevelIncome from './Commponents/LevelIncome';
import Refferal from './Commponents/Refferal';
import HeritageIncome from './Commponents/HeritageIncome';
import CommunityIncome from './Commponents/CommunityIncome';
import Stake from './Commponents/Stake';
import Withdrawal from './Commponents/Withdrawal';
import Profile from './Commponents/Profile';
import EditProfile from './Commponents/EditProfile';
import Affiliate from './Commponents/Affiliate';
import StakingIncome from './Commponents/StakingIncome';
import CommunityRoyalty from './Commponents/CommunityRoyalty';
import Unstake from './Commponents/Unstake';
import Ledger from './Commponents/Ledger';
import CommingSoon from './Commponents/CommingSoon';
import LevelRanks from './Commponents/LevelRanks';
import SuperIncome from './Commponents/SuperIncome';

function App() {
  return (
   <>
    {/* <Dashboard/> */}
    <BrowserRouter>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/staking" element={<Staking />} />
        <Route path="/refferal" element={<Refferal />} />
        <Route path="/level-income" element={<LevelIncome />} />
        <Route path="/super-income" element={<SuperIncome />} />
        <Route path="/level-ranks" element={<LevelRanks />} />
        <Route path="/staking-income" element={<StakingIncome />} />
        <Route path="/heritage-income" element={<HeritageIncome />} />
        <Route path="/community-income" element={<CommunityIncome />} />
        <Route path="/community-royalty" element={<CommunityRoyalty />} />
        <Route path="/stake" element={<Stake />} />
        <Route path="/stake/:link_ref_address" element={<Stake />} />
        <Route path="/wtihdrawal" element={<Withdrawal />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/edit-profile" element={<EditProfile />} />
        <Route path="/" element={<Affiliate />} />
        <Route path="/unstake" element={<Unstake />} />
        <Route path="/ledger" element={< Ledger />} />
        <Route path="/comming-soon" element={< CommingSoon />} />
      </Routes>
    </BrowserRouter>
   </>
  );
}

export default App;
