import React, { useContext, useEffect, useState } from "react";
import Sidebar from "./Comman/Sidebar";
import Header from "./Comman/Header";
import { OnmaxContext } from "../OnmaxContext";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

export default function Stake() {
  const { link_ref_address } = useParams();
  // console.log("link_ref_address",link_ref_address);
  const {
    formatAddress,
    copyaddress,
    web3,
    blocks,
    account,
    balance,
    rate,
    smuser,
    toastSuccess,
    library,
    dbuser,
    tokenrate,
    stakcontract,
    toastError,
    getudata,
    settings,
    esigner,
    ethers,
    getuserDB,
    connectMetamask,
  } = useContext(OnmaxContext);
  const [show, setshow] = useState(false);
  const [amount, setamount] = useState(0);
  const [maticfee, setmaticfee] = useState(0);
  const [maticfee$, setmaticfee$] = useState(0);
  const [total_tokens, settotal_tokens] = useState(0);
  const [trdone, settrdone] = useState(false);
  const [hash, sethash] = useState("");
  const [loader, setloader] = useState(false);
  const [referral, setreferral] = useState("");
  const [incap, setincap] = useState("3");
  const [incappass, setincappass] = useState("0");
  const [incapper, setincapper] = useState("0");
  const [block, setblock] = useState("A");
  var navigate = useNavigate();

  const onDeposit = async () => {
    settrdone(true);

    sethash("");
    if (!account) {
      connectMetamask();
      toastError("Connect first");
      settrdone(false);
      return;
    }
    if (blocks === null) {
      settrdone(false);
      return;
    }
    if (!amount || amount < 0) {
      toastError("Enter amount");
      settrdone(false);
      return;
    }
    // if (Number(balance) < Number(total_tokens)) {
    //   toastError("Insufficient balance");
    //   return;
    // }
    console.log("Reee",referral);
    if (!referral) {
      toastError("Enter Referral Address");
      settrdone(false);
      return;
    }
    var data = await getudata(referral);
    if (data.data.error) {
      toastError("Invalid Referral Address");
      settrdone(false);
      return "";
    }
    if (dbuser !== null && dbuser.last_staking > amount) {
      toastError(`Stake ${dbuser.last_staking} or more`);
      settrdone(false);
      return;
    }
    // let contract = new library.eth.Contract(
    //   JSON.parse(settings.contract_abi),
    //   settings.contract
    // );
    let token_contract = new library.Contract(
      settings.token,
      JSON.parse(settings.token_abi),
      esigner
    );
    const fmin = await stakcontract.findblockandfee(
      ethers.utils.parseEther(`${amount}`),
      incappass
    );
    const calcstakingfee = await stakcontract.calcstakingfee(
      ethers.utils.parseEther(`${amount}`),
      fmin[1]
    );
    const allowance = await token_contract.allowance(
      account,
      settings.contract
    );
    if (block === "A") {
      if (
        Number(amount) < blocks.minstakeblocka ||
        Number(amount) > blocks.maxstakeblocka
      ) {
        toastError("Enter amount between min and max");
        settrdone(false);
        return;
      }
    } else {
      if (
        Number(amount) < blocks.minstakeblockb ||
        Number(amount) > blocks.maxstakeblockb
      ) {
        toastError("Enter amount between min and max");
        settrdone(false);
        return;
      }
    }
// console.log("${Number(calcstakingfee) + 1000000000}", ethers.utils.parseEther(`${amount}`),
// incappass,
// referral,`${Number(calcstakingfee) + 1100000000}`);
    // const gasEstimate = await stakcontract.estimateGas.stake(
    //   ethers.utils.parseEther(`${amount}`),
    //   incappass,
    //   referral,
    //   { value: `${Number(calcstakingfee) + 1100000000}` }
    // );
    async function main() {
      // var gsl = await stakcontract//   .stake(ethers.utils.parseEther(`${amount}`), incappass, referral)
      //   .estimateGas( { from: account, value: `${Number(calcstakingfee) + 1000000000}` });
      //   console.log("gasghd ",gsl,await library.eth.getGasPrice()
      //   );
      try {
        var stk = await stakcontract.stake(
          ethers.utils.parseEther(`${amount}`),
          incappass,
          referral,
          { value: `${Number(calcstakingfee) + 1100000000}` }
        );

        const receipt = await stk.wait();
        sethash(receipt.transactionHash);
        toastSuccess("Transaction done");
        getuserDB();
        settrdone(false);
        navigate("/dashboard");
        await axios
          .post(process.env.REACT_APP_BACKEND_LINK, {
            method: "stake",
            submethod: "insert",
            key: process.env.REACT_APP_KEY,
            address: account,
            referral_address: referral,
            hash: receipt.transactionHash,
            amount: amount,
            token: total_tokens,
            matic: maticfee,
            matic_rate: rate,
            token_rate: tokenrate,
            t_months: block === "A" ? 15 : 24,
            b_type: block === "A" ? 0 : 1,
            incap_per: incapper,
            income_capability: incap,
          })
          .then((res) => {
            // var dedata = decryptData(res.data);
            if (res.data.error) {
              return "";
            }
          });
      } catch (error) {
        toastError("Transaction declined");
        settrdone(false);
        return;
      }
      // .send(
      //   {
      //     from: account,
      //     value: `${Number(calcstakingfee) + 1000000000}`,
      //     nonce: library.utils.toHex(count),
      //   },
      //   async function (err, transactionid) {
      //     if (err) {
      //       // console.log("error", err);
      //       toastError("Transaction declined");
      //       settrdone(false);
      //       return;
      //     }
      //     sethash(transactionid);
      //     await axios
      //       .post(process.env.REACT_APP_BACKEND_LINK, {
      //         method: "stake",
      //         submethod: "insert",
      //         key: process.env.REACT_APP_KEY,
      //         address: account,
      //         referral_address: referral,
      //         hash: transactionid,
      //         amount: amount,
      //         token: total_tokens,
      //         matic: maticfee,
      //         matic_rate: rate,
      //         token_rate: tokenrate,
      //         t_months: block === "A" ? 15 : 24,
      //         b_type: block === "A" ? 0 : 1,
      //         incap_per: incapper,
      //         income_capability: incap,
      //       })
      //       .then((res) => {
      //         // var dedata = decryptData(res.data);
      //         if (res.data.error) {
      //           return "";
      //         }
      //       });
      //   }
      // )
      // .then(() => {
      //   toastSuccess("Transaction done");
      //   getuserDB();
      //   settrdone(false);
      //   navigate("/dashboard");
      // })
      // .catch(() => {});
    }
    // if (Number(library.utils.fromWei(allowance, "ether")) < total_tokens) {
    if (Number(ethers.utils.formatEther(allowance)) < total_tokens) {
      // if (true) {
      try {
        var approv = await token_contract.approve(
          settings.contract,
          ethers.utils.parseEther("100000000")
        );
        const receipt = await approv.wait();
        console.log(receipt);
        toastSuccess("Token Approved");
        main();
      } catch (error) {
        console.log("error", error);
        toastError("Token not approved");
        settrdone(false);
        return;
      }
    } else {
      main();
    }
  };

  const setvaluess = async () => {
    if (!stakcontract || !settings.contract) {
      return;
    }
    console.log("approv", ethers);
    const fmin = await stakcontract.findblockandfee(
      ethers.utils.parseEther(`${amount}`),
      incappass
    );
    const calcstakingfee = await stakcontract.calcstakingfee(
      ethers.utils.parseEther(`${amount}`),
      fmin[1]
    );
    setmaticfee(ethers.utils.formatEther(calcstakingfee));
    setmaticfee$(ethers.utils.formatEther(fmin[2]));
    setincapper(ethers.utils.formatEther(fmin[1]));
  };
  useEffect(() => {
    if (dbuser !== null) {
      if (dbuser.referral !== null) {
        setreferral(dbuser.ref_address);
      }
    } else {
      setreferral(link_ref_address);
    }
  }, [link_ref_address, dbuser, account]);
  useEffect(() => {
    if (block === "B") {
      if (incap === "3") {
        setincap("4");
      }
    }
  }, [block]);
  useEffect(() => {
    settotal_tokens(amount / tokenrate);
  }, [amount]);
  useEffect(() => {
    setvaluess();
  }, [library, settings, amount, incap]);
  return (
    <>
      <div className="body-bg">
        <Sidebar />
        <Header />
        <section className="nftmax-adashboard nftmax-show">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-5 col-md-12 col-sm-12 col-12 nftmax-main__colum">
                <div className="nftmax-body">
                  <div className="nftmax-dsinner">
                    <div className="nftmax-inner__heading">
                      <h2 className="nftmax-inner__page-title">Stake</h2>
                    </div>
                    <div className="nftmax-personals">
                      <div className="row d-flex justify-content-center">
                        <div className="col-lg-12 col-md-12 col-12 align-items-center nftmax-personals__content">
                          <div className="nftmax-ptab">
                            <div className="nftmax-ptabs__inner mt-4">
                              <div className="tab-content" id="nav-tabContent">
                                <div
                                  className="tab-pane fade show active"
                                  id="id1"
                                  role="tabpanel"
                                >
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="nftmax-ptabs__separa">
                                        <div className="nftmax-ptabs__form-main">
                                          <div className="nftmax__item-form--group">
                                            <label className="nftmax__item-label">
                                              Referral Address{" "}
                                            </label>
                                            {dbuser !== null ? (
                                              dbuser.referral !== null ? (
                                                <input
                                                  className="nftmax__item-input"
                                                  type="text"
                                                  value={referral}
                                                />
                                              ) : (
                                                <input
                                                  className="nftmax__item-input"
                                                  type="text"
                                                  placeholder="Enter Referral Address"
                                                  value={referral}
                                                  onChange={(e) =>
                                                    {setreferral(e.target.value);console.log("das",e.target.value);}
                                                  }
                                                />
                                              )
                                            ) : (
                                              <input
                                                className="nftmax__item-input"
                                                type="text"
                                                placeholder="Enter Referral Address"
                                                value={referral}
                                                onChange={(e) =>
                                                  {setreferral(e.target.value);console.log("das",e.target.value);}
                                                }
                                              />
                                            )}
                                          </div>
                                          <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="nftmax-ptabs__separa">
                                                <div className="nftmax-ptabs__form-main">
                                                  <div className="nftmax__item-form--group mx-2 d-flex justify-content-center align-items-center">
                                                    <label className="nftmax__item-label mx-2 w-100">
                                                      Block A
                                                    </label>
                                                    <input
                                                      className="nftmax__item-input mx-2 w-25"
                                                      type="radio"
                                                      value="A"
                                                      defaultChecked={true}
                                                      name="block"
                                                      onChange={(e) =>
                                                        setblock(e.target.value)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                              <div className="nftmax-ptabs__separa">
                                                <div className="nftmax-ptabs__form-main">
                                                  <div className="nftmax__item-form--group mx-2 d-flex justify-content-center align-items-center">
                                                    <label className="nftmax__item-label mx-2  w-100">
                                                      Block B
                                                    </label>
                                                    <input
                                                      className="nftmax__item-input mx-2 w-25"
                                                      type="radio"
                                                      value="B"
                                                      name="block"
                                                      onChange={(e) =>
                                                        setblock(e.target.value)
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="col-12 text-center">
                                              <p>
                                                {block === "A"
                                                  ? `Min: $ ${
                                                      blocks !== null
                                                        ? blocks.minstakeblocka
                                                        : 0
                                                    } Max $ ${
                                                      blocks !== null
                                                        ? blocks.maxstakeblocka
                                                        : 0
                                                    }`
                                                  : `Min: $ ${
                                                      blocks !== null
                                                        ? blocks.minstakeblockb
                                                        : 0
                                                    } Max $ ${
                                                      blocks !== null
                                                        ? blocks.maxstakeblockb
                                                        : 0
                                                    }`}
                                              </p>
                                            </div>
                                          </div>
                                          <div className="nftmax__item-form--group">
                                            <label className="nftmax__item-label">
                                              Amount{" "}
                                            </label>
                                            <input
                                              className="nftmax__item-input"
                                              type="text"
                                              placeholder="Enter Amount"
                                              value={amount}
                                              onChange={(e) =>
                                                setamount(e.target.value)
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="nftmax-ptabs__separa">
                                        <div className="nftmax-ptabs__form-main d-flex ">
                                          <div className="nftmax__item-form--group mx-2 d-flex justify-content-center align-items-center">
                                            <label className="nftmax__item-label mx-2">
                                              {block === "A" ? "3x" : "4x"}
                                            </label>
                                            <input
                                              className="nftmax__item-input mx-2"
                                              type="radio"
                                              name="xinc"
                                              value={block === "A" ? "3" : "4"}
                                              defaultChecked={true}
                                              onChange={(e) => {
                                                setincap(e.target.value);
                                                setincappass("0");
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div className="nftmax-ptabs__separa">
                                        <div className="nftmax-ptabs__form-main d-flex ">
                                          <div className="nftmax__item-form--group mx-2 d-flex justify-content-center align-items-center">
                                            <label className="nftmax__item-label mx-2">
                                              6x
                                            </label>
                                            <input
                                              className="nftmax__item-input mx-2"
                                              type="radio"
                                              name="xinc"
                                              value="6"
                                              onChange={(e) => {
                                                setincap(e.target.value);
                                                setincappass("1");
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pt-3 text-light text-center">
                                    Stake : {total_tokens}{" "}
                                    {process.env.REACT_APP_TICKER}
                                  </div>
                                  <div className="text-light text-center">
                                    Token Rate : ${tokenrate}
                                  </div>
                                  <div className="text-light text-center">
                                    Rate : ${rate} / 1 BNB
                                  </div>
                                  <div className="text-light text-center">
                                    Fee : {Number(maticfee).toFixed(2)} BNB ($
                                    {maticfee$})
                                  </div>
                                  {hash ? (
                                    <div className="p-3 text-light text-center">
                                      Hash :{" "}
                                      <a
                                        href={`${process.env.REACT_APP_EXPLORER}tx/${hash}`}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        #: {formatAddress(hash)}
                                      </a>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div className="nftmax__item-button--group nftmax__ptabs-bottom">
                                    {trdone ? (
                                      <img
                                        className="border-0"
                                        src="/assets/img/loader.gif"
                                        alt="loading"
                                        height={100}
                                        width={100}
                                      />
                                    ) : (
                                      <button
                                        className="nftmax__item-button--single nftmax-btn nftmax-btn__bordered bg radius "
                                        onClick={() => onDeposit()}
                                      >
                                        Stake
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
